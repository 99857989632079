import type ColumnTable from "arquero/dist/types/table/column-table";
import type { Struct } from "arquero/dist/types/table/transformable";

export function extrapolateBenchmark(
	aq: any,
	data: ColumnTable,
	maxDaysCount: number,
) {
	const columns = data.columnNames();
	if (!columns.includes("days_count")) {
		// missing column in data
		return null;
	}

	const extrapolatedCols: Record<string, number> = {};
	columns.map((column: string) => {
		if (["total", "performance"].includes(column) || /\d+/.test(column))
			extrapolatedCols[column] = aq.escape(
				(d: Struct) => (d[column] * maxDaysCount) / d.days_count,
			);
	});

	// sort again after extrapolation
	const extrapolatedData = data
		.derive(extrapolatedCols)
		.orderby(aq.desc("total"))
		.reify();
	return extrapolatedData;
}

export function deExtrapolateBenchmark(
	aq: any,
	data: ColumnTable,
	maxDaysCount: number,
) {
	const columns = data.columnNames();
	if (!columns.includes("days_count")) {
		// missing column in data
		return null;
	}

	const deExtrapolatedCols: Record<string, number> = {};
	columns.map((column: string) => {
		if (["total", "performance"].includes(column) || /\d+/.test(column))
			deExtrapolatedCols[column] = aq.escape(
				(d: Struct) => (d[column] * d.days_count) / maxDaysCount,
			);
	});

	// sort again after extrapolation
	const deExtrapolatedData = data
		.derive(deExtrapolatedCols)
		.orderby(aq.desc("total"))
		.reify();
	return deExtrapolatedData;
}
