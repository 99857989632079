import Ajv from "ajv";
import { type ReactNode, createContext, useEffect, useReducer } from "react";
import smooch from "smooch";
import { ROOT_URL, brandSetting, routerBasename } from "src/config";
import schema from "src/schemas/UserFromAPI.json";
import uuidv4 from "src/utils/uuidv4";
import { t } from "ttag";
import type {
	ActionMap,
	AuthState,
	AuthUser,
	DjangoContextType,
	RealAuthUser,
	UserFromAPI,
} from "../@types/auth";
import { axiosInstance, forageStore } from "../utils/axios";

// ----------------------------------------------------------------------

const ajv = new Ajv();
const validateUser = ajv.compile(schema);

enum Types {
	Initial = "INITIALIZE",
	Login = "LOGIN",
	Disconnecting = "DISCONNECTING",
	Disconnected = "DISCONNECTED",
	Register = "REGISTER",
	Reload = "RELOAD",
	ServerError = "SERVER_ERROR",
	ForgotEmail = "FORGOT_EMAIL",
	UpdateUser = "UPDATE_USER",
}

type DjangoAuthPayload = {
	[Types.Initial]: {
		isAuthenticated: boolean;
		user: AuthUser | RealAuthUser;
	};
	[Types.Disconnecting]: undefined;
	[Types.Login]: {
		isAuthenticated: boolean;
		user: RealAuthUser;
	};
	[Types.Disconnected]: undefined;
	// [Types.Register]: {
	//   user: AuthUser;
	// };
	[Types.Reload]: undefined;
	[Types.ServerError]: undefined;
	[Types.ForgotEmail]: {
		userEmail: string;
	};
	[Types.UpdateUser]: {
		isAuthenticated: boolean;
		user: AuthUser | RealAuthUser;
	};
};

export type DjangoActions =
	ActionMap<DjangoAuthPayload>[keyof ActionMap<DjangoAuthPayload>];

const initialState: AuthState = {
	isAuthenticated: false,
	isInitialized: false,
	isDisconnecting: false,
	needsReload: false,
	user: null,
	serverError: false,
	userEmail: "",
};

const DjangoReducer = (state: AuthState, action: DjangoActions) => {
	switch (action.type) {
		case Types.Initial:
			return {
				isAuthenticated: action.payload.isAuthenticated,
				isInitialized: true,
				isDisconnecting: false,
				needsReload: false,
				user: action.payload.user,
				serverError: false,
				userEmail: "",
			};
		case Types.Disconnecting:
			return {
				...state,
				isDisconnecting: true,
			};
		case Types.Login:
			broadcast({ isAuthenticated: true });
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
				serverError: false,
			};
		case Types.Disconnected:
			broadcast({ isAuthenticated: false });
			return {
				...state,
				isDisconnecting: false,
				isAuthenticated: false,
				user: {
					hijacked: state.user?.hijacked || false,
					language: state.user?.language, // Keep current language
				},
				serverError: false,
			};

		// case Types.Register:
		//   return {
		//     ...state,
		//     isAuthenticated: true,
		//     user: action.payload.user,
		//     serverError: false,
		//   };

		case Types.Reload:
			return {
				...state,
				needsReload: true,
				serverError: false,
			};

		case Types.ServerError:
			return {
				...state,
				serverError: true,
			};

		case Types.ForgotEmail:
			return {
				...state,
				userEmail: action.payload.userEmail,
			};
		case Types.UpdateUser:
			return {
				...state,
				user: action.payload.user,
				serverError: false,
			};
	}
};

const AuthContext = createContext<DjangoContextType | null>(null);

// ----------------------------------------------------------------------

const instanceId = uuidv4();
let broadcastChannel: BroadcastChannel | undefined;
try {
	broadcastChannel = new BroadcastChannel("AuthenticatedStatus");
} catch (error) {
	if (process.env.NODE_ENV !== "test") {
		console.error("BroadcastChannel not supported ?", error);
	}
}

const broadcast = (message: { [key: string]: any }) => {
	message.instanceId = instanceId;
	setTimeout(() => {
		broadcastChannel?.postMessage(JSON.stringify(message));
	}, 1000);
};

// ----------------------------------------------------------------------

type AuthProviderProps = {
	children: ReactNode;
};

const no_cache = {
	cache: { ignoreCache: true, exclude: { filter: () => true } },
	validateStatus: () => true,
};

function AuthProvider({ children }: AuthProviderProps) {
	const [state, dispatch] = useReducer(DjangoReducer, initialState);

	broadcastChannel?.addEventListener("message", (event) => {
		// console.log('broadcastChannel', event.data)
		const data = JSON.parse(event.data);
		if (data.instanceId !== instanceId) {
			if (
				state.isInitialized &&
				state.isAuthenticated !== data.isAuthenticated
			) {
				dispatch({ type: Types.Reload });
			}
		}
	});

	const cleanUser = (data: { [key: string]: any }) => {
		const valid = validateUser(data);
		if (!valid) {
			console.error(
				"invalid API user data",
				JSON.stringify(validateUser.errors),
			);
		}

		const api_user = data as UserFromAPI;
		const auth_user: RealAuthUser = {
			...api_user,
			needsTour: api_user?.lastTourDate < "2022-11-01T00:00:00.000Z",
		};

		// DEV: To manually check the auto-launched tour, use this:
		// auth_user.needsTour = true

		return auth_user;
	};

	useEffect(() => {
		const initialize = async () => {
			try {
				// Smart Impulse rest api
				// GET /api/rest/user
				// - soit code 403 -> rediriger vers le formulaire de login -> puis connection
				// - soit code 200 avec data {'first_name': '...', ...} -> lancer l'app

				const response = await axiosInstance.get("api/rest/user/", {
					/* responseType: 'json', */ ...no_cache,
				});
				if (response === undefined) {
					throw new Error("Network error");
				}
				if (response.status === 403) {
					// console.log('User not connected')
					const { user } = JSON.parse(response.data);
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: false,
							user,
						},
					});
				} else if (response.status === 200) {
					const { user } = JSON.parse(response.data);

					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: true,
							user: cleanUser(user),
						},
					});
				} else if (response.status === 502) {
					dispatch({ type: Types.ServerError });
				} else {
					throw new Error("Invalid response status");
				}
			} catch (err) {
				if (err.code === "ERR_NETWORK" || err.message === "Network Error") {
					// example in dev: CORS error
					dispatch({ type: Types.ServerError });
				} else {
					console.error("error when calling rest user", err);
					const { user } = err;
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: false,
							user: user,
						},
					});
				}
			}
		};

		initialize();
	}, []);

	const getCSRF = async () => {
		const get_csrf = await axiosInstance.get("api/rest/user/csrf/", {
			responseType: "json",
			...no_cache,
		});

		if (get_csrf === undefined || get_csrf.status !== 200)
			throw new Error("Failed to load the csrf page");

		const { token } = get_csrf.data;
		return token;
	};

	const loadUser = async () => {
		const response = await axiosInstance.get("api/rest/user/", {
			responseType: "json",
			...no_cache,
		});
		if (response.status !== 200)
			throw new Error(t`Failed to load the user information`);

		const { user } = response.data;
		if (user.hijacked) await forageStore.clear();
		dispatch({
			type: Types.Login,
			payload: {
				isAuthenticated: true,
				user: cleanUser(user),
			},
		});
	};

	const loginRest = async (
		email: string,
		password: string,
		remember: boolean,
	) => {
		// 'GET /api/rest/user/csrf/' pour avoir le cookie csrftoken
		// POST '/login?no_redirect', pour se connecter, qui renvoie un code 204 en cas de succès du login
		// et ça définit le cookie sessionid qui est httpOnly (donc pas accessible en JS)

		let token;
		try {
			token = await getCSRF(); // and also receive header "set-cookie: csrftoken=***"
		} catch (_error) {
			throw new Error(t`Impossible to interact with the server`);
		}

		if (!!token) {
			const form_data = new FormData();
			form_data.append("csrfmiddlewaretoken", token);
			form_data.append("username", email);
			form_data.append("password", password);
			if (remember) form_data.append("remember", "");
			const post_login = await axiosInstance.post(
				"/login?no_redirect",
				form_data,
				no_cache,
			);

			if (post_login.status === 200) {
				let msg = "";
				try {
					const el = document.createElement("html");
					el.innerHTML = post_login.request.responseText;
					msg =
						el.getElementsByClassName("errorlist nonfield")[0].textContent ||
						"";
				} catch (_error) {
					// could not load content, use default message
				}

				// django a rechargé le même formulaire: erreur de saisie
				throw new Error(msg || t`Invalid credentials`);
			}

			if (post_login.status === 403) {
				// CSRF error
				// It is also triggered in incognito mode with cross-origin
				throw new Error(t`The security check has failed`);
			}

			if (post_login.status === 204) {
				// Login successful: load dashboard
				await loadUser();
				return;
			}

			throw new Error(t`Unexpected error response from the server`);
			// en cours de navigation sur l'app, si l'API répond avec code 403, rediriger vers la page de login
		} else {
			throw new Error(t`The security token could not be loaded`);
		}
	};

	const loginSSO = async (email: string): Promise<string> => {
		let token;
		try {
			token = await getCSRF(); // and also receive header "set-cookie: csrftoken=***"
		} catch (_error) {
			throw new Error(t`Impossible to interact with the server`);
		}

		if (!!token) {
			const form_data = new FormData();
			form_data.append("csrfmiddlewaretoken", token);
			form_data.append("username", email);
			const response = await axiosInstance({
				url: "/login_sso",
				method: "POST",
				baseURL: ROOT_URL,
				data: form_data,
				...no_cache,
			});
			return response.data;
		} else {
			throw new Error(t`The security token could not be loaded`);
		}
	};

	// const register = async (email: string, password: string, firstName: string, lastName: string) => {
	//   const response = await axiosInstance.post('api/account/register', {
	//     email,
	//     password,
	//     firstName,
	//     lastName,
	//   }, no_cache);
	//   const { accessToken, user } = response.data;

	//   window.localStorage.setItem('accessToken', accessToken);
	//   dispatch({
	//     type: Types.Register,
	//     payload: {
	//       user,
	//     },
	//   });
	// };

	const doLogout = async () => {
		await forageStore.clear();
		smooch.destroy();
		dispatch({ type: Types.Disconnected });
	};

	const logout = async () => {
		dispatch({ type: Types.Disconnecting });

		// To bypass the automated JSON parsing (which fails on HTML), add this option:
		// 'readHeaders' to interpret the headers from the redirection to '/login', which says 'cache-control: max-age=0, no-cache'
		const response = await axiosInstance.get("logout/?no_redirect", {
			cache: { ignoreCache: true, readHeaders: true },
		});
		if (response.status === 200) {
			await doLogout();
		} else {
			throw new Error("Logout failed");
		}
	};

	const seti18n = async (lang: string, reload = true) => {
		const token = await getCSRF();

		const form_data = new FormData();
		form_data.append("language", lang);
		form_data.append("csrfmiddlewaretoken", token);
		const response = await axiosInstance.post(
			"api/rest/user/change_language/",
			form_data,
			no_cache,
		);

		if (response.status === 204) {
			await forageStore.clear();
			if (reload) window.location.reload();
		}
	};

	const resetPassword = async (email: string) => {
		const token = await getCSRF();

		const form_data = new FormData();
		form_data.append("email", email);
		form_data.append("csrfmiddlewaretoken", token);
		form_data.append(
			"public_url",
			`${document.location.protocol}//${document.location.host}${routerBasename}`,
		);
		const response = await axiosInstance.post(
			"/password_reset/",
			form_data,
			no_cache,
		);

		if (
			response.status !== 200 ||
			!response.request.responseURL.endsWith("/login")
		) {
			const parsedResponse = JSON.parse(response.data);
			if (parsedResponse && parsedResponse.error) {
				throw new Error(
					t`Unable to reset password for this domain (SSO authentication).`,
				);
			}

			throw new Error("Error in form submission");
		}
	};

	const definePassword = async (
		new_password: string,
		uidb64: string,
		token: string,
	) => {
		const csrfToken = await getCSRF();

		const form_data = new FormData();
		form_data.append("new_password1", new_password);
		form_data.append("new_password2", new_password);
		form_data.append("csrfmiddlewaretoken", csrfToken);

		const url = `/password_reset/${uidb64}/${token}/`;
		const response = await axiosInstance.post(url, form_data, no_cache);

		if (
			response.status !== 200 ||
			!response.request.responseURL.endsWith("/login")
		) {
			throw new Error("Error in form submission");
		}
	};

	const releaseHijack = async () => {
		const token = await getCSRF();

		const form_data = new FormData();
		form_data.append("csrfmiddlewaretoken", token);
		const response = await axiosInstance.post(
			"/hijack/release-hijack/?next=/api/rest/user/",
			form_data,
			no_cache,
		);

		if (response.status === 200) {
			await forageStore.clear();
			window.location.reload();
		}
	};

	const canUseChat = () => {
		if (!brandSetting.withChat) return false;
		if (!state.isInitialized) return false;
		if (!state.isAuthenticated) return false;
		if (state.user?.hijacked) return false;

		return true;
	};

	const forgotEmail = (userEmail: string) => {
		dispatch({ type: Types.ForgotEmail, payload: { userEmail } });
	};

	const updateUserRight = (state: {
		isAuthenticated: boolean;
		user: AuthUser | RealAuthUser;
	}) => {
		const { user, isAuthenticated } = state;
		dispatch({
			type: Types.UpdateUser,
			payload: { isAuthenticated, user: cleanUser(user as any) },
		});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				loginRest,
				loginSSO,
				logout,
				doLogout,
				// register,
				seti18n,
				resetPassword,
				definePassword,
				releaseHijack,
				canUseChat,
				forgotEmail,
				updateUserRight,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
