import { zonedTimeToUtc } from "date-fns-tz";
import type {
	ApiSiteContent,
	MultiSiteContent,
	metaUpdateParam,
} from "src/@types/SiteContent";
import {
	type ChartTypes,
	ElectricityTypes,
	TemperatureTypes,
} from "src/@types/charts";
import type { MagicButtonGraphState, NodeAPIType } from "src/@types/magic";
import type { apiDataKey } from "src/contexts/ChartOptionsContext";
import {
	Frequency,
	endOfPeriod,
	formatISOInTimeZone,
	naiveEndOfPeriod,
	startOfPeriod,
} from "src/utils/timeFormatter";
import type { APIGraphSeries, GraphOptions } from "../@types/graphSeries";
import type { SitesList } from "../@types/sites";
import { axiosInstance } from "../utils/axios";

import Ajv from "ajv";
import addFormats from "ajv-formats";
import * as aq from "arquero";
import axios from "axios";
import { isValid } from "date-fns";
import { flattenDeep, isArray, uniqueId } from "lodash";
import type { SiteContent } from "src/@types/SiteContent";
import { SpecialDay } from "src/@types/SpecialDay";
import type { ContactData } from "src/components/others/ContactForm";
import type { FeedbackData } from "src/components/others/FeedbackForm";
import { API_URL } from "src/config";
import type { DateRangeDate } from "src/contexts/dateRangeContext";
import schemaSiteContent from "src/schemas/SiteContent.json";
import schemaSitesList from "src/schemas/SitesList.json";
import { convertToWeekMask } from "src/utils/weekDays";
import { t } from "ttag";
import { callAggregator } from "./dataManager";

const ajv = new Ajv({ allowUnionTypes: true });
addFormats(ajv);

const validateSiteContent = ajv.compile(schemaSiteContent);
const validateSitesList = ajv.compile(schemaSitesList);

// Installations avec des données disponibles :
// Client; Site label; site uuid; installation label; installation uuid
// - Client Smeg; Tour Odéon; 1dfc809a-fe50-4fe6-811f-7dcbd0c12ff0; Niveau 49 TGBT 3; 3873d623-7eff-426b-ad55-530a2cfbb4a4

// /api/dashboard/aggregation/<aggregation_type>/<installation_uuid>/
//    > aggregation_type=
//         - typical_week: 7 barres d'énergie
//         - typical_power_week: 7 jours de puissance à N minutes
//         - typical_day: 1 jour de puissance à N minutes, + paramètre days=weekday|weekend
// /api/dashboard/aggregation/energy_per_<granularity>/<installation_uuid>/ -> granularity = day si on fait toutes les agrégations côté front
// /api/dashboard/aggregation/total_energy/<installation_uuid>/  -> pas utile si on fait des agrégations côté front
// /api/dashboard/loadcurve/<installation_uuid>/
// /api/dashboard/power/<installation_uuid>/
// /api/dashboard/temperature/<installation_uuid>/
// /api/dashboard/temperature/degree-day/<installation_uuid>/

// New API Dashboard V2
// /api/rest/user/ (info de l'utilisateur connecté)
// /api/rest/user/nodes/ (liste des sites d'un utilisateur)
// /api/rest/site/<uuid>/ (informations détaillées d'un site)
// /api/rest/installation/<uuid>/events/ (évenements d'une installation <- très peu de cas dans la bdd actuellement)

// actual example call :
// https://dashboard.smart-impulse.com/api/dashboard/power/bc77f11a-6899-46ca-9bd0-82c8bbca652a?date_start=2017-05-19+00%3A00%3A00%2B02%3A00&date_end=2017-05-20+00%3A00%3A00%2B02%3A00&base_columns=start_time%2Ctotal%2Cadditional_sensor

// actual example call :
// https://dashboard.smart-impulse.com/api/dashboard/aggregation/energy_per_day/bc77f11a-6899-46ca-9bd0-82c8bbca652a?date_start=2017-05-14+00%3A00%3A00&date_end=2017-05-20+23%3A55%3

// actual example call :
// https://dashboard.smart-impulse.com/api/dashboard/temperature/bc77f11a-6899-46ca-9bd0-82c8bbca652a?date_start=2017-05-19+00%3A00%3A00%2B02%3A00&date_end=2017-05-20+00%3A00%3A00%2B02%3A00

// actual example call :
// https://dashboard.smart-impulse.com/api/dashboard/temperature/degree-day/bc77f11a-6899-46ca-9bd0-82c8bbca652a?date_start=2017-05-14+00%3A00%3A00&date_end=2017-05-20+23%3A59%3A59&interval=len1day

// mock/UUID/temperature.json + filtre par date

// const http = axios.create({
//   baseURL: "http://localhost:8009/api/rest",
//   headers: {
//     "Content-type": "application/json"
//   }
// });
// const uuid = '4f145f06-3976-4505-bf2a-7afa7a304f5e'

export const getAll = async () => {
	// TODO si SmartImpulse, flag /api/rest/user/nodes/?by_customer à activer par défaut côté serveur

	// let url = 'api/rest/user/nodes/?by_customer&include_context=True';
	// let url = '/api/rest/user/nodes/?include_context=True';
	// let urlDev = '/api/rest/user/nodes/?by_customer&include_context=True&include_site_groups=True'
	// let urlProd = '/api/rest/user/nodes/?by_customer&include_context=True'
	// let url = (process.env.NODE_ENV === 'development' || window.location.host.includes("review-apps")) ? urlDev : urlProd
	const url =
		"/api/rest/user/nodes/?by_customer&include_context=True&include_site_groups=True";

	// let url = urlDev

	const paramsWithMetadata: Record<string, any> = { withMetadata: true };

	// if (process.env.NODE_ENV !== 'production') {
	//   url += '&merge_levels' // to test that our code handles multiple levels
	// }
	const response = await axiosInstance.get(url, {
		cache: { ignoreCache: true },
		params: paramsWithMetadata,
	});
	const SiteList: SitesList = response.data.sites;

	const valid = validateSitesList(SiteList);
	if (!valid) {
		console.error(
			"getAll does not respect the schema",
			JSON.stringify(validateSitesList.errors),
		);
	}

	// console.log("[StateTracker] Got results from 'nodes' API");
	return SiteList;
};

export const getSite = async (
	uuid: string | string[],
	abort_signal?: AbortSignal,
) => {
	if (!uuid) throw new Error("You must provide a UUID");
	// const paramsWithMetadata: Record<string, any> = { withMetadata: true };
	let response: any;
	response = await axiosInstance.get(`/api/rest/site/${uuid}`, {
		cache: { ignoreCache: true },
		signal: abort_signal,
	});
	const api_data = response.data as ApiSiteContent;

	const valid = validateSiteContent(api_data);
	if (!valid) {
		console.error(
			"getSite does not respect the schema",
			JSON.stringify(validateSiteContent.errors),
		);
	}

	// return { unique_id: uniqueId(), ...response.data } as SiteContent

	api_data.metadata.sharedNotes =
		api_data.metadata.sharedNotes || "<p><br></p>";

	return {
		unique_id: uniqueId(),
		has_alerts_access: api_data.metering_plan
			.concat(api_data.virtual)
			.map((item) => item.can_access_alerts || item.has_children_with_alerts)
			.includes(true),
		can_view_metadata: api_data.metering_plan
			.concat(api_data.virtual)
			.map((item) => item.can_view_metadata || item.has_children_view_metadata)
			.includes(true),
		can_edit_metadata: api_data.metering_plan
			.concat(api_data.virtual)
			.map((item) => item.can_edit_metadata || item.has_children_edit_metadata)
			.includes(true),
		...api_data,
	} as SiteContent;
};

export const getMultisites = async (
	uuid: string,
	type: string,
	abort_signal?: AbortSignal,
) => {
	if (!uuid) throw new Error("You must provide a UUID");
	let response: any;
	response = await axiosInstance.get(`/api/rest/node/${uuid}/?type=${type}`, {
		cache: { ignoreCache: true },
		signal: abort_signal,
	});
	const api_data = response.data as MultiSiteContent;
	return api_data;
};

export const getMacroCategories = async () => {
	const macrocategories = axiosInstance.get(
		`/api/rest/benchmark/macrocategory/`,
	);
	return macrocategories;
};

export const getBenchmark = async (
	installations: string[],
	abort_signal?: AbortSignal,
) => {
	if (!installations) throw new Error("You must provide installations");
	const token = await getCSRF();
	try {
		const response = await aq.loadArrow(`${API_URL}api/rest/benchmark/`, {
			fetch: {
				method: "POST",
				body: JSON.stringify({ installations: installations }),
				headers: {
					"X-CSRFToken": token,
					"Content-Type": "application/json",
				},
				credentials: "include",
				signal: abort_signal,
			},
			columns: aq.all(),
		});
		return response;
	} catch (error) {
		console.log("Benchmark API request failed :", error);
		return aq.table([]);
	}
};

export function chartsDataUrl(
	chartType: ChartTypes,
	state: MagicButtonGraphState,
	dateRange: DateRangeDate,
	Site: SiteContent,
	extraParams: any,
	forDownload: boolean,
): [string, GraphOptions] | undefined {
	// enum ElectricityTypes {
	//   Loadcurve = 'loadcurve',
	//   Power = 'power',
	//   EnergyDay = 'energy_per_day',
	//   EnergyMonth = 'energy_per_month',
	//   EnergyWeek = 'energy_per_week',
	//   EnergyYear = 'energy_per_year',
	//   DegreeDay = 'degree-day',
	//   Temperature = 'temperature',
	// }

	const uuid = state.installation;

	let date_start;
	let date_end;

	if (state.chartType === undefined) return undefined;
	// if (state.dateStart === null) return undefined
	// if (state.dateEnd === null) return undefined
	if (dateRange[0] === null || !isValid(dateRange[0])) return undefined;
	if (dateRange[1] === null || !isValid(dateRange[1])) return undefined;

	// energy : lire current_data_start/current_data_end
	const installation = state.installationObject;
	if (installation !== undefined && installation.current_data_start) {
		date_start = formatISOInTimeZone(
			zonedTimeToUtc(
				startOfPeriod(
					installation.current_data_start,
					Site.timezone,
					Frequency.Day,
				),
				Site.timezone,
			),
			Site.timezone,
		);
	}
	if (installation !== undefined && installation.current_data_end) {
		date_end = formatISOInTimeZone(
			zonedTimeToUtc(
				endOfPeriod(
					installation.current_data_end,
					Site.timezone,
					Frequency.Day,
				),
				Site.timezone,
			),
			Site.timezone,
		);
	}

	const chartsUsingDates = [
		ElectricityTypes.Power,
		ElectricityTypes.Loadcurve,
		ElectricityTypes.EnergyDay,
		ElectricityTypes.EnergyWeek,
		ElectricityTypes.EnergyMonth,
		ElectricityTypes.EnergyYear,
		ElectricityTypes.EnergyDonut,
		ElectricityTypes.TypicalDay,
		ElectricityTypes.TypicalWeek,
		ElectricityTypes.CompareTypicalDay,
		ElectricityTypes.PluriannualEvolution,
	];

	if (chartsUsingDates.includes(state.chartType)) {
		// if power: date picker
		// start = midnight
		// state.dateStart
		date_start = formatISOInTimeZone(
			zonedTimeToUtc(dateRange[0], Site.timezone),
			Site.timezone,
		);
		// end = endOfDay
		// state.dateEnd
		date_end = formatISOInTimeZone(
			zonedTimeToUtc(
				naiveEndOfPeriod(dateRange[1], Frequency.Day),
				Site.timezone,
			),
			Site.timezone,
		);
		// console.log('dates from daterange: ', date_start, date_end)

		// end - 7 days
		// let fns_date_end = utcToZonedTime(date_end, Site.timezone)
		// date_start = formatISOInTimeZone(startOfDayZoned(subDays(fns_date_end, 7), Site.timezone), Site.timezone)
	}

	const params: GraphOptions = {
		date_start: date_start,
		date_end: date_end,
	};

	const types_without_base_columns: ChartTypes[] = [
		TemperatureTypes.Temperature,
		TemperatureTypes.DegreeDay,
	];
	if (!types_without_base_columns.includes(chartType)) {
		params.base_columns = "start_time,total,additional_sensor";
	}

	// console.log(chartType, 'chartType')
	// console.log(uuid, 'uuid')
	// console.log(options, 'options')

	// ?date_start=2022-03-02+00%3A00%3A00%2B01%3A00
	// date_end=2022-03-03+00%3A00%3A00%2B01%3A00
	// base_columns=start_time%2Ctotal%2Cadditional_sensor
	// chart: {
	//   power: () => power,
	//   energy: () => energy,
	//   temperature: () => temperature,
	//   degreeDay: () => degreeDay,
	// }
	//https://proxy-maximej.internal.smartimpulse.com/api/dashboard/energy/4f145f06-3976-4505-bf2a-7afa7a304f5e?date_start=2022-03-02T00:00:00%2B01:00&date_end=2022-03-03T00:00:00%2B01:00&base_columns=start_time,total,additional_sensor

	// https://dashboard.smart-impulse.com/api/dashboard/aggregation/energy_per_day/bc77f11a-6899-46ca-9bd0-82c8bbca652a?date_start=2017-05-14+00%3A00%3A00&date_end=2017-05-20+23%3A55%3

	//https://proxy-maximej.internal.smartimpulse.com/api/dashboard/aggregation/energy_per_day/4f145f06-3976-4505-bf2a-7afa7a304f5e?date_start=2022-03-02T00:00:00%2B01:00&date_end=2022-03-03T00:00:00%2B01:00&base_columns=start_time,total,additional_sensor

	const ChartsWithoutDownload: ChartTypes[] = [
		ElectricityTypes.CompareTypicalDay,
		// ElectricityTypes.PluriannualEvolution,
	];

	if (forDownload && ChartsWithoutDownload.includes(chartType))
		return undefined;

	if (uuid !== undefined || uuid !== "") {
		switch (chartType) {
			case ElectricityTypes.Power:
				return [`api/dashboard/power/${uuid}`, params];
			case ElectricityTypes.EnergyDonut:
				return forDownload
					? [`api/dashboard/aggregation/total_energy/${uuid}`, params]
					: [`api/dashboard/aggregation/energy_per_day/${uuid}`, params];
			case ElectricityTypes.EnergyYear:
				return forDownload
					? [`api/dashboard/aggregation/energy_per_year/${uuid}`, params]
					: [`api/dashboard/aggregation/energy_per_day/${uuid}`, params];
			case ElectricityTypes.PluriannualEvolution:
			case ElectricityTypes.EnergyMonth:
				return forDownload
					? [`api/dashboard/aggregation/energy_per_month/${uuid}`, params]
					: [`api/dashboard/aggregation/energy_per_day/${uuid}`, params];
			case ElectricityTypes.EnergyWeek:
				return forDownload
					? [`api/dashboard/aggregation/energy_per_week/${uuid}`, params]
					: [`api/dashboard/aggregation/energy_per_day/${uuid}`, params];
			case ElectricityTypes.EnergyDay:
				return [`api/dashboard/aggregation/energy_per_day/${uuid}`, params];
			// case ElectricityTypes.CompareTypicalDay:
			//   // typical_week
			//   // typical_power_week
			//   // typical_day
			//   // typical_day_per_hour
			//   params['days'] = ''
			//   return [`api/dashboard/aggregation/typical_day/${uuid}`, params]
			case ElectricityTypes.CompareTypicalDay:
			case ElectricityTypes.TypicalDay:
				if (
					forDownload &&
					state.selectedDays === SpecialDay.OccupiedUnoccupiedDays
				) {
					// no export for TypicalDay in occupied/unoccupied mode
					return undefined;
				}

				if (extraParams.days) {
					params.days = extraParams.days || "weekday"; // or 'weekend' or mask '0000000'
					return [`api/dashboard/aggregation/typical_day/${uuid}`, params];
				} else {
					if (isArray(state.selectedDays)) {
						params.days = convertToWeekMask(state.selectedDays);
					}
					return [`api/dashboard/aggregation/typical_day/${uuid}`, params];
				}
			case ElectricityTypes.TypicalWeek:
				return [`api/dashboard/aggregation/typical_power_week/${uuid}`, params];
			case TemperatureTypes.NoTemperature:
				return undefined;
			case TemperatureTypes.Temperature:
				switch (state.chartType) {
					case ElectricityTypes.EnergyWeek:
					// params.interval = 'len1week'
					// break
					case ElectricityTypes.EnergyMonth:
					case ElectricityTypes.PluriannualEvolution:
					// params.interval = 'len1month'
					// break
					case ElectricityTypes.EnergyYear:
					// params.interval = 'len1year'
					// break
					case ElectricityTypes.EnergyDay:
						params.interval = "len1day";
						break;
					case ElectricityTypes.TypicalDay:
					case ElectricityTypes.TypicalWeek:
					case ElectricityTypes.CompareTypicalDay:
					case ElectricityTypes.EnergyDonut:
						return undefined;
					default:
						break;
				}
				return [`api/dashboard/temperature/${uuid}`, params];
			case TemperatureTypes.DegreeDay:
				params.heating_reference = state.hdd || 18;
				params.cooling_reference = state.cdd || 18;
				params.method =
					state.installationObject?.metadata?.degree_day_references?.method;
				switch (state.chartType) {
					case ElectricityTypes.EnergyWeek:
					// params.interval = 'len1week'
					// break
					case ElectricityTypes.EnergyMonth:
					case ElectricityTypes.PluriannualEvolution:
					// params.interval = 'len1month'
					// break
					case ElectricityTypes.EnergyYear:
					// params.interval = 'len1year'
					// break
					case ElectricityTypes.EnergyDay:
						params.interval = "len1day";
						break;
					case ElectricityTypes.Loadcurve:
					case ElectricityTypes.Power:
						// force temperature
						return [`api/dashboard/temperature/${uuid}`, params];
					case ElectricityTypes.TypicalDay:
					case ElectricityTypes.TypicalWeek:
					case ElectricityTypes.CompareTypicalDay:
					case ElectricityTypes.EnergyDonut:
					default:
						return undefined;
				}
				return [`api/dashboard/temperature/degree-day/${uuid}`, params];
			case ElectricityTypes.Loadcurve:
				return [`api/dashboard/loadcurve/${uuid}`, params];
		}
	}
}

type ChartsDataApiResult = [string, apiDataKey, APIGraphSeries[]];

export const chartsDataApi = async (
	callIdentifier: string,
	key: apiDataKey,
	chartType: ChartTypes,
	state: MagicButtonGraphState,
	dateRange: DateRangeDate,
	Site: SiteContent,
	extraParams: any,
	abort_signal?: AbortSignal,
) => {
	const apiUrl = chartsDataUrl(
		chartType,
		state,
		dateRange,
		Site,
		extraParams,
		false,
	);
	if (apiUrl === undefined)
		return [callIdentifier, key, []] as ChartsDataApiResult;

	const [url, params] = apiUrl;

	// const response = await axiosInstance.get(url, { params, signal: abort_signal })
	const response = await callAggregator(url, { params }, abort_signal);
	if (response.status !== 200) {
		throw new Error("API failed");
	}
	if (!isArray(response.data)) {
		throw new Error("API did not return an array");
	}
	return [
		callIdentifier,
		key,
		response.data as APIGraphSeries[],
	] as ChartsDataApiResult;
};

export const getAlerts = async (uuid: string, type?: string) => {
	const urlNew = `api/rest/alerts/${uuid}/?type=${type}`;
	const url = type !== undefined ? urlNew : `api/rest/site/${uuid}/alerts/`;

	const response = await axiosInstance.get(url, {
		responseType: "json",
		cache: { ignoreCache: true, exclude: { filter: () => true } },
	});
	return response;
};

export const getAlertsNotification = async (
	site_uuid: string, // to change
	alert_id?: string,
	page?: number,
	id?: string,
	history?: boolean,
	type?: "site" | "customer" | "site_group" | "user",
) => {
	// const type = ''

	const urlNew = `api/rest/alert/active_range/${site_uuid}/?type=${type}`;
	let url =
		type === undefined
			? `api/rest/site/${site_uuid}/alert/active_range/`
			: urlNew;

	if (id !== undefined) {
		url += `${id}/`;
	}

	const params: Record<string, any> = { history: false };
	if (alert_id !== undefined) {
		params.alert_id = alert_id;
	}
	if (page !== undefined) {
		params.page = page;
	}
	if (history !== undefined) {
		params.history = history;
	}
	const response = await axiosInstance.get(url, {
		responseType: "json",
		params: params,
		cache: { ignoreCache: true, exclude: { filter: () => true } },
	});
	return response;
};

const getCSRF = async () => {
	const no_cache = {
		cache: { ignoreCache: true, exclude: { filter: () => true } },
		validateStatus: () => true,
	};
	const get_csrf = await axiosInstance.get("api/rest/user/csrf/", {
		responseType: "json",
		...no_cache,
	});

	if (get_csrf === undefined || get_csrf.status !== 200)
		throw new Error(t`Failed to load your user information (csrf)`);

	const { token } = get_csrf.data;
	return token;
};

export const alertsLastSeen = async (
	site_uuid: string,
	method: "get" | "put",
	type?: string,
) => {
	if (method === "get") {
		const urlNew = `api/rest/notification/seen_flag/${site_uuid}/?type=${type}`;
		const url = type
			? urlNew
			: `api/rest/site/${site_uuid}/notification/seen_flag/`;

		const response = await axiosInstance.get(url, {
			responseType: "json",
			cache: { ignoreCache: true, exclude: { filter: () => true } },
		});
		return response;
	} else {
		const response = await axiosInstance.put(
			type
				? `api/rest/notification/seen_flag/${site_uuid}/?type=${type}`
				: `api/rest/site/${site_uuid}/notification/seen_flag/`,
			{},
			{ headers: { "X-CSRFToken": await getCSRF() } },
		);
		return response;
	}
};

export const getCommonModels = async () => {
	return axiosInstance.get(`/api/rest/common/models/`, {
		cache: { ignoreCache: true },
	});
};

export const updateMetaDataService = async (metaUpdate: metaUpdateParam) => {
	// console.log('post', metaUpdate)

	metaUpdate = { ...metaUpdate };
	if (!!metaUpdate) {
		// console.log('postif', metaUpdate)

		// const csrfToken = await getCSRF()
		// let form_data = new FormData()
		// form_data.append(metaUpdate, csrfToken)
		// form_data.append('metaUpdate', JSON.stringify(metaUpdate))
		// JSON.stringify(metaUpdate)
		const metadataUpdate = await axiosInstance.put(
			`/api/rest/metadata/update/`,
			metaUpdate,
			{
				cache: { ignoreCache: true },
				headers: { "X-CSRFToken": await getCSRF() },
			},
		);
		return metadataUpdate;
	}
};
export const updateLastTourDate = async (page: string) => {
	// console.log('steps page', page)
	const form_data = new FormData();
	form_data.append("page", page);
	// console.log('steps postif', form_data)
	const updateLastTourDate = await axiosInstance.post(
		`/tour-finished/`,
		form_data,
		{
			cache: { ignoreCache: true },
			headers: { "X-CSRFToken": await getCSRF() },
		},
	);
	return updateLastTourDate;
};

export const sendFeedback = async (feedback_data: FeedbackData) => {
	const response = await axiosInstance.put(
		`/contact/rest_send_feedback`,
		feedback_data,
		{
			headers: {
				"X-CSRFToken": await getCSRF(),
				"x-requested-with": "XMLHttpRequest",
			},
		},
	);
	return response;
};

export const contactUs = async (contact_data: ContactData) => {
	const response = await axiosInstance.put(
		`/contact/rest_contact_us`,
		contact_data,
		{ headers: { "X-CSRFToken": await getCSRF() } },
	);
	return response;
};

export const createPageView = async (type?: string, uuid?: string) => {
	const url =
		type && uuid
			? `/api/rest/pageview/${type}/${uuid}/`
			: "/api/rest/pageview/";
	const response = await axiosInstance.get(url, {
		cache: { ignoreCache: true, exclude: { filter: () => true } },
	});
	return response;
};

export const getVersionFromAPi: () => Promise<string> = async () => {
	if (process.env.NODE_ENV === "test") {
		return "";
	}

	try {
		const res = await axios.get(`${process.env.PUBLIC_URL}/version.txt`, {
			cache: { ignoreCache: true },
		});
		if (res.data !== undefined) {
			return res.data;
		} else {
			return "";
		}
	} catch (error) {
		console.error(
			`No internet connection found. App is running in offline mode. Version can't be compare.`,
			error,
		);
		return "";
	}
};

// async (version?:string) => {
//   const response = await axios.get(`${process.env.PUBLIC_URL}/version.txt`, { responseType: 'text', cache: { ignoreCache: true }});
//   return response.data
// }

export const getMetadataHistory = async (uuid: string) => {
	if (!uuid) throw new Error("You must provide a UUID");

	const response = await axiosInstance.get(
		`/api/rest/metadata/history/${uuid}`,
		{ cache: { ignoreCache: true } },
	);

	return response.data;
};

export const getDeviceGroupsMC = async (uuid: string, type: NodeAPIType) => {
	if (!uuid) throw new Error("You must provide a UUID");
	if (!type) throw new Error("You must provide a type");

	const response = await axiosInstance.get(
		`/api/rest/device_groups_mc/${uuid}/?type=${type}`,
		{ cache: { ignoreCache: true } },
	);

	return response.data;
};

export const getAggregationExcel = async (
	endpoint: string,
	installations: string[],
	// dates in iso format
	date_start: string,
	date_end: string,
	nodeLabel: string,
	benchmark_type: string,
	extrapolate: boolean,
	// unit only use by benchmark
	unit_suffix?: string,
	per_site?: boolean,
	abort_signal?: AbortSignal,
) => {
	if (!installations) throw new Error("You must provide installations");

	let params: Record<string, any> = {
		installations: installations,
		date_start: date_start,
		date_end: date_end,
		node_label: nodeLabel,
		benchmark_type: benchmark_type,
		extrapolate: extrapolate,
	};

	if (unit_suffix) params.unit_suffix = unit_suffix;
	if (per_site) params.per_site = per_site;
	try {
		const response = await axiosInstance.post(
			`api/rest/aggregation/${endpoint}/`,
			params,
			{
				headers: { "X-CSRFToken": await getCSRF() },
				signal: abort_signal,
				responseType: "blob",
			},
		);
		return response;
	} catch (error) {
		console.log("Error on aggregated data excel :", error);
		return aq.table([]);
	}
};

export const updateUser = async (
	userData: {
		email: string;
		firstName: string;
		lastName: string;
		jobTitle?: string;
		phoneNumber?: string;
		secondaryNumber?: string;
	},
	abort_signal?: AbortSignal,
) => {
	try {
		const response = await axiosInstance.patch(`api/rest/user/`, userData, {
			headers: { "X-CSRFToken": await getCSRF() },
			signal: abort_signal,
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			return {
				status: error.response.status,
				errors: error.response.data,
			};
		}
		return {
			status: 500,
			message: error,
		};
	}
};
