import { type ElementType, Suspense } from "react";
import {
	Navigate,
	type RouteObject,
	createBrowserRouter,
	useLocation,
} from "react-router-dom";
// import { cp } from 'fs';
// import { PATH_DASHBOARD } from 'src/routes/paths';
import { ChartTypesUrl } from "src/@types/charts";
import AuthLayout from "src/layouts/AuthLayout";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// import { PATH_AFTER_LOGIN, routerBasename } from '../config';
// components
import LoadingScreen from "../components/others/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
// guards
import GuestGuard from "../guards/GuestGuard";
// guards
import useAuth from "../hooks/useAuth";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from "../layouts/dashboard/MainContent";
// import useDateRange from 'src/hooks/useDateRange';
// import useLocales from 'src/hooks/useLocales';

// import timeFormat, {DateInput} from 'src/utils/timeFormatter'
// import useSiteContext from 'src/hooks/useSiteContext';

import { AlertProvider } from "src/contexts/AlertsContext";
// import { useTheme } from '@mui/material/styles';
import AppTourContextProvider from "src/contexts/AppTourContext";
import { SitesProvider } from "src/contexts/NodesContext";
import { SiteProvider } from "src/contexts/SiteContext";

// ----------------------------------------------------------------------
import CustomPrompt from "src/components/others/CustomPrompt";
import { routerBasename } from "src/config";
import { BenchmarkProvider } from "src/contexts/BenchmarkContext";
import { lazyWithRetry as lazy } from "src/utils/lazyWithRetry";

const Loadable = (Component: ElementType) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	// biome-ignore lint/correctness/useHookAtTopLevel: React-hooks/rules-of-hooks
	const { pathname } = useLocation();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	// biome-ignore lint/correctness/useHookAtTopLevel: React-hooks/rules-of-hooks
	const { isAuthenticated } = useAuth();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	// const { path } = useParams();

	const isDashboard = pathname.includes("/") && isAuthenticated;
	// console.log(path, 'params to analys')
	return (
		<Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
			<Component {...props} />
		</Suspense>
	);
};

type NullableRouteObject =
	| (Omit<RouteObject, "children"> & { children?: NullableRouteObject[] })
	| null
	| undefined;

const removeEmptyChildren = (items: NullableRouteObject[]): RouteObject[] => {
	const filtered: RouteObject[] = [];
	items.forEach((item) => {
		if (item === null || item === undefined) {
		} else if (item.children === undefined) {
			filtered.push({
				...item,
				children: undefined,
			});
		} else if (item.children.length === 0) {
		} else {
			filtered.push({
				...item,
				index: false,
				children: removeEmptyChildren(item.children),
			});
		}
	});

	return filtered;
};

const DEV_ONLY = process.env.NODE_ENV === "development";
// const DEV_ONLY = false

export const getRoutes = (params: { catchAll: boolean }): RouteObject[] => {
	const { catchAll } = params;
	return removeEmptyChildren([
		{
			path: "auth",
			element: (
				<GuestGuard>
					<AuthLayout />
				</GuestGuard>
			),
			children: [
				{ path: "login", element: <Login /> },
				// {path: 'register',element: (<Register />),},
				// { path: 'register-unprotected', element: <Register /> },
				// { path: 'verify', element: <VerifyCode /> },
				{ path: "reset-password", element: <ResetPassword /> },
				{
					path: "define-password/:uidb64/:token/",
					element: <DefinePassword />,
				},
				// DEV_ONLY ? { path: 'login-unprotected', element: <Login /> } : null,
			],
		},
		// Dashboard Routes
		//   1. routes with a node in the URL or redirecting to a page with a node
		{
			path: "/",
			element: (
				<AuthGuard>
					<SitesProvider>
						<SiteProvider>
							<BenchmarkProvider>
								<AppTourContextProvider>
									<AlertProvider>
										<DashboardLayout />
									</AlertProvider>
								</AppTourContextProvider>
							</BenchmarkProvider>
						</SiteProvider>
					</SitesProvider>
				</AuthGuard>
			),
			children: [
				{ path: "", element: <Navigate to="/dashboard" replace /> }, // redirect to Analysis while the dashboard is empty
				{
					path: "dashboard",
					children: [
						{ path: ":customer", element: <DashboardRooter /> },
						{ path: ":node", element: <DashboardRooter /> },
						{ path: ":node/:installation", element: <Dashboard /> },
						{ path: "", element: <Dashboard /> },
						{ path: "*", element: <Dashboard /> },
					],
				},
				{ path: "table/:customer", element: <DashboardTableMap /> },
				{ path: "map/:customer", element: <DashboardTableMap /> },
				{
					path: "analysis",
					children: Object.entries(ChartTypesUrl)
						.map(([chartType, url]) => {
							return {
								path: `${url}/:node/:installation`,
								element: <Analysis chartType={chartType} />,
							};
						})
						.concat(
							Object.entries(ChartTypesUrl).map(([chartType, url]) => {
								return {
									path: `${url}/:node/:installation`,
									element: <Analysis chartType={chartType} />,
								};
							}),
						)
						.concat([
							{ path: ":node/:installation", element: <Analysis /> },
							{ path: "", element: <Analysis /> },
							{ path: "*", element: <Analysis /> },
						]),
				},
				// { path: 'actions', element: <Actions /> },
				{
					path: "info",
					children: [
						{ path: "", element: <Site /> },
						{ path: ":node", element: <Site /> },
						{ path: ":node/kpi/:indicator", element: <Site /> },
						{ path: ":node/:installation", element: <Site /> },
					],
				},

				// { path: 'projects', element: <Projects /> },
				// { path: 'issues', element: <Issues /> },
				// { path: 'meteringplan', element: <Meteringplan/>},
				{
					path: "alerts",
					children: [
						/////////////////////////////// For node
						{ path: "", element: <AlertsHome /> },
						{ path: ":node", element: <AlertsHome /> },
						{ path: ":node/:alert_id", element: <AlertsHome /> },
						{
							path: ":node/:alert_id/notifications/:notif_id",
							element: <AlertsHome />,
						},
						{ path: "notifications", element: <AlertsHome /> },
						{ path: "notifications/:node", element: <AlertsHome /> },
						{ path: "notifications/:node/:notif_id", element: <AlertsHome /> },
						/////////////////////////////// For customer
						{ path: "table/:customer", element: <AlertsHome /> },
						// { path: 'table/:customer/:alert_id', element: <AlertsHome /> },
						// { path: 'table/:customer/:alert_id/notifications/:notif_id', element: <AlertsHome /> },
						{ path: "notifications/table/:customer", element: <AlertsHome /> },
						// { path: 'notifications/table/:customer/:notif_id', element: <AlertsHome /> },
						DEV_ONLY ? { path: "create", element: <AlertEdit /> } : null,
						DEV_ONLY ? { path: ":name", element: <AlertEdit /> } : null,
					],
				},
				{
					path: "reports",
					children: [
						// { path: '', element: <ReportsList /> },
						// { path: 'notifications', element: <ReportsNotifications /> },
						// { path: 'create', element: <ReportEdit /> },
						// { path: ':name', element: <ReportEdit /> },
					],
				},
				{
					path: "export",
					children: [
						// { path: '', element: <Export /> },
						// { path: 'api', element: <API /> },
					],
				},
				{
					path: "users",
					children: DEV_ONLY
						? [
								{ element: <Users />, index: true },
								// { path: 'profile', element: <UserProfile /> },
								// { path: 'cards', element: <UserCards /> },
								// { path: 'list', element: <UserList /> },
								{ path: "create", element: <UserEdit /> },
								{ path: ":name", element: <UserEdit /> },
								// { path: 'account', element: <UserAccount /> },
							]
						: [],
				},
			],
		},
		//   2. routes without a node in the URL
		{
			path: "/",
			element: (
				<AuthGuard>
					<DashboardLayout outsideSiteContext={true} />
				</AuthGuard>
			),
			children: [
				{ path: "settings", element: <Settings /> },
				{ path: "dashboard/terms", element: <Terms /> },
				{ path: "dashboard/privacy", element: <Privacy /> },
			],
		},

		// Main Routes
		{
			path: "/",
			element: <LogoOnlyLayout />,
			children: [
				{ path: "terms", element: <Terms /> },
				{ path: "privacy", element: <Privacy /> },
				{ path: "coming-soon", element: <ComingSoon /> },
				{ path: "maintenance", element: <Maintenance /> },
				{ path: "500", element: <Page500 /> },
				{ path: "404", element: <NotFound /> },
				{ path: "empty", element: <NoSites /> },
			],
		},
		catchAll ? { path: "*", element: <ConvertOldUrls /> } : null,
	]);
};

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
// const Logout = Loadable(lazy(() => import('../pages/auth/Logout')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(
	lazy(() => import("../pages/auth/ResetPassword")),
);
const DefinePassword = Loadable(
	lazy(() => import("../pages/auth/DefinePassword")),
);
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const NoSites = Loadable(
	lazy(() => import("../pages/dashboard/empty/NoSites")),
);

// dashboard
const Dashboard = Loadable(
	lazy(() => import("../pages/dashboard/dashboard/Dashboard")),
);
const DashboardRooter = Loadable(
	lazy(() => import("../pages/dashboard/dashboard/DashboardRooter")),
);

const DashboardTableMap = Loadable(
	lazy(
		() => import("../pages/dashboard/dashboard/multisite/DashboardTableMap"),
	),
);

// analysis
const Analysis = Loadable(
	lazy(() => import("../pages/dashboard/analysis/Analysis")),
);

// const Actions = Loadable(lazy(() => import('../pages/dashboard/actions/Actions')));
const Site = Loadable(lazy(() => import("../pages/dashboard/info/Site")));

const AlertsHome = Loadable(
	lazy(() => import("../pages/dashboard/alerts/AlertsHome")),
);
const AlertEdit = Loadable(
	lazy(() => import("../pages/dashboard/alerts/Alert")),
);

// const ReportsList = Loadable(lazy(() => import('../pages/dashboard/reports/Reports')));
// const ReportsNotifications = Loadable(lazy(() => import('../pages/dashboard/reports/ReportsNotifications')));
// const ReportEdit = Loadable(lazy(() => import('../pages/dashboard/reports/Report')));

const Users = Loadable(lazy(() => import("../pages/dashboard/users/Users")));
const UserEdit = Loadable(lazy(() => import("../pages/dashboard/users/User")));

// const Export = Loadable(lazy(() => import('../pages/dashboard/export/Export')));
// const API = Loadable(lazy(() => import('../pages/dashboard/export/API')));

// TODO
// const Projects = Loadable(lazy(() => import('../pages/dashboard/projects/Projects')));
// const Issues = Loadable(lazy(() => import('../pages/dashboard/issues/Issues')));
// const Meteringplan = Loadable(lazy(() => import('../pages/dashboard/info/MeteringPlan')));
const Settings = Loadable(
	lazy(() => import("../pages/dashboard/settings/Settings")),
);

const Terms = Loadable(lazy(() => import("../pages/others/Terms")));
const Privacy = Loadable(lazy(() => import("../pages/others/Privacy")));

const NotFound = Loadable(lazy(() => import("../pages/others/Page404")));

// old-Dashboard
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/user/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/user/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/user/UserAccount')));
// const Chat = Loadable(lazy(() => import('../pages/old-dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/old-dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/old-dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/old-dashboard/Kanban')));

// Main
// const HomePage = Loadable(lazy(() => import('../pages/others/Home')));
// const About = Loadable(lazy(() => import('../pages/others/About')));
// const Contact = Loadable(lazy(() => import('../pages/others/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/others/Faqs')));
const ComingSoon = Loadable(lazy(() => import("../pages/others/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/others/Maintenance")));
// const Pricing = Loadable(lazy(() => import('../pages/others/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/others/Payment')));
const Page500 = Loadable(lazy(() => import("../pages/others/Page500")));
// const NotFound = Loadable(lazy(() => import('../pages/others/Page404')));

const ConvertOldUrls = Loadable(
	lazy(() => import("../pages/dashboard/redirects/ConvertOldUrls")),
);

export const router = createBrowserRouter(getRoutes({ catchAll: true }), {
	basename: routerBasename,
});
